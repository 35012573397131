import { LoginResponse } from "@/_api";
import * as Rx from "rxjs";
import { Buffer } from 'buffer';

export type Caches = {
  accessToken?: string;
};

export type UserInfo = {
  sub: string;
  avatar: string;
  roles: string[];
};

const cacheSubjects = new Rx.BehaviorSubject(JSON.parse(localStorage.getItem("bimeri_info") ?? "{}"));

async function setValue(values: LoginResponse) {
  const final = {
    ...cacheSubjects.value,
    ...values,
  };
  cacheSubjects.next(final);
  localStorage.setItem("bimeri_info", JSON.stringify(final));
}

const resetValue = () => {
  cacheSubjects.next({});
  localStorage.removeItem("bimeri_info");
};

function getInfo() {
  const tokenInfo = cacheSubjects?.value?.["token"];
  if (tokenInfo) {
    try {
      const val = JSON.parse(Buffer.from(tokenInfo.split(".")[1], "base64").toString("utf8"));
      return val;
    } catch (error) {
      console.error(error);
    }
  }
  return {};
}
const cacheService = {
  cache: cacheSubjects.asObservable(),
  getValue(key: string) {
    return cacheSubjects?.value?.[key] ?? "";
  },
  setValue,
  resetValue,
  getInfo,
};
export default cacheService;
