import { useMutation } from "@tanstack/react-query";
import { Button, Form, Input, message } from "antd";
import clientService from "../../../services/http-client";
import Footer from "../../footer";
import Header from "../../header";
const Support = () => {
  const [form] = Form.useForm();
  const sendMutation = useMutation({
    mutationFn: (value) => clientService.api.supportCreate(value),
    onSuccess: () => {
      message.success("Gửi thông tin thành công!, Chúng tôi sẽ liên hệ lại với bạn sớm nhất có thể!");
      form.resetFields();
    },
  });
  return (
    <>
      <div className="main-wrapper">
        <Header activeMenu="support" />

        <div className="breadcrumb-bar">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-12">
                <h2 className="breadcrumb-title">Liên hệ với chúng tôi</h2>
                <nav aria-label="breadcrumb" className="page-breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="/">Trang chủ</a>
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      Liên hệ
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div className="page-content">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-8 mx-auto">
                <div className="support-wrap">
                  <h5>Gửi thông tin</h5>
                  <Form
                    layout="vertical"
                    form={form}
                    onFinish={(value) => {
                      sendMutation.mutate(value);
                    }}
                  >
                    <Form.Item label="Họ và tên" name="hoTen" rules={[{ required: true, message: "Vui lòng nhập họ và tên!" }]}>
                      <Input />
                    </Form.Item>
                    <Form.Item label="Email" name="email" rules={[{ required: true, message: "Vui lòng nhập email!" }]}>
                      <Input />
                    </Form.Item>
                    <Form.Item label="Tiêu đề" name="tieuDe" rules={[{ required: true, message: "Vui lòng nhập tiêu đề!" }]}>
                      <Input />
                    </Form.Item>
                    <Form.Item label="Nội dung" name="noiDung" rules={[{ required: true, message: "Vui lòng nhập nội dung!" }]}>
                      <Input.TextArea rows={5} />
                    </Form.Item>
                    <Form.Item>
                      <Button danger type="default" style={{ width: 100 }} size="large" htmlType="submit">
                        Gửi
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default Support;
