import { Modal, message } from "antd";
import { Api } from "../../_api";
import cacheService from "../cache-service";
import CodeMessage from "./code-message";

const clientService = new Api({
  baseURL: process.env.REACT_APP_BACKEND_URL ?? "",
});
clientService.instance.interceptors.request.use(
  (configOriginal) => {
    const config = configOriginal;
    let accessToken = cacheService.getValue("token");
    if (accessToken) {
      if (config.headers) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
    }
    return config;
  },
  (error) => {
    message.error(error);
    Promise.reject(error);
  }
);
clientService.instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error?.response.status === 401) {
      cacheService.resetValue();
      window.location.href = "/login";
    }
    let msg =
      error?.response.data?.message ??
      CodeMessage[error?.response?.status] ??
      "Something went wrong";
    if (error?.response.status !== 422) {
      Modal.error({
        title: "Lỗi",
        width: 500,
        content: msg,
      });
    } else {
      throw error.response.data;
    }
  }
);
export default clientService;
