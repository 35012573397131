import { Form, Input, Modal, message } from "antd";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from "react-router-dom";
import clientService from "../../../services/http-client";
import { LoginImg, logo } from "../../imagepath";

const ForgotPassword = () => {
  var settings = {
    //autoWidth: true,
    items: 2,
    margin: 25,
    dots: true,
    nav: true,
    navText: ['<i className="fas fa-arrow-left"></i>', '<i className="fas fa-arrow-right"></i>'],

    loop: true,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 1,
      },
      1170: {
        items: 1,
      },
    },
  };
  const onFinish = async (value) => {
    try {
      const res = await clientService.api.authForgotPassword(value);
      if (res) {
        message.success("Vui lòng kiểm tra email của bạn !");
      }
    } catch (e) {
      Modal.error({
        title: "Lỗi",
        content: e.msg ?? "Email không tồn tại",
      });
    }
  };
  return (
    <>
      <div className="main-wrapper">
        <div className="row">
          {/* Login Banner */}
          <div className="col-md-6 login-bg">
            <div className="welcome-login">
              <div className="login-banner">
                <img src={LoginImg} className="img-fluid" alt="Logo" />
              </div>
              <div className="mentor-course text-center">
                <h2>
                  Đào tạo trực tuyến <br />
                  Viện Y Dược Lâm Sàng
                </h2>
              </div>
            </div>
          </div>
          {/* /Login Banner */}
          <div className="col-md-6 login-wrap-bg">
            {/* Login */}
            <div className="login-wrapper">
              <div className="loginbox">
                <div className="img-logo">
                  <img src={logo} className="img-fluid" alt="Logo" />
                  <div className="back-home">
                    <Link to="/">Quay lại trang chủ</Link>
                  </div>
                </div>
                <h1>Quên mật khẩu ?</h1>
                <div className="reset-password">
                  <p>Vui lòng nhập email để lấy lại mật khẩu.</p>
                </div>
                <Form layout="vertical" onFinish={onFinish} size="large">
                  <Form.Item name={"email"} label="Email" rules={[{ required: true, message: "Vui lòng nhập email" }]}>
                    <Input placeholder="Nhập email" />
                  </Form.Item>
                  <div className="d-grid">
                    <button className="btn btn-start" type="submit">
                      Gửi
                    </button>
                  </div>
                </Form>
              </div>
            </div>
            {/* /Login */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
