import { useQuery } from "@tanstack/react-query";
import clientService from "../../services/http-client";
import Footer from "../footer";
import Header from "../header";
const ThongTin = () => {
  // activeMenu={"Details"}
  const { data, isLoading } = useQuery({
    queryKey: ["thongTinFindById"],
    queryFn: () => clientService.api.thongTinFindById(1),
  });
  return (
    <>
      <div className="main-wrapper">
        <Header activeMenu={"thong-tin"} />
        <div className="breadcrumb-bar">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-12">
                <h2 className="breadcrumb-title">Chính sách người dùng</h2>
                <nav aria-label="breadcrumb" className="page-breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="/">Trang chủ</a>
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      Thông tin
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <section className="course-content">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                  <div dangerouslySetInnerHTML={{ __html: data?.noiDung }} />
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </>
  );
};

export default ThongTin;
