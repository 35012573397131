import { useQuery } from "@tanstack/react-query";
import { ConfigProvider, Tree } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Header from "../../../../components/header";
import clientService from "../../../../services/http-client";
import Footer from "../../../footer";
import GridInnerPage from "./gridInnerPage";

function useSearchParams() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function convertToTree(data) {
  if (!data) {
    return [];
  }
  const tree = data
    .map((item) => {
      if (!item) {
        return null;
      }
      return {
        value: item?.id,
        title: item.ten,
        key: item?.id,
        children: convertToTree(item.con),
      };
    })
    .filter(Boolean);
  return tree;
}
const CourseGrid = () => {
  const params = useSearchParams();

  const defaultfilter = params
    ?.get("filter")
    ?.split(",")
    ?.map((item) => Number(item));
  const { data: danhMucDaoTao } = useQuery({
    queryKey: ["danhMucDaoTaoFindfull"],
    queryFn: async () => {
      const data = await clientService.api.danhMucDaoTaoFindfull();
      return convertToTree(data);
    },
  });

  const { data: quangCao } = useQuery({
    queryKey: ["quangCaoFind", { viTri: 1 }],
    queryFn: () =>
      clientService.api.quangCaoFind({
        query: {
          where: {
            viTri: 1,
          },
        },
      }),
  });

  const [filter, setFilter] = useState(defaultfilter ?? []);
  const [search, setSearch] = useState(params.get("search"));
  useEffect(() => {
    if (danhMucDaoTao) {
      const filter = [];
      danhMucDaoTao
        .filter((item) => defaultfilter?.includes(item.value) ?? true)
        .forEach((item) => {
          filter.push(item.value);
          if (item.children) {
            item.children.forEach((item) => {
              filter.push(item.value);
            });
          }
        });
      setFilter(filter);
    }
  }, [danhMucDaoTao]);
  return (
    <>
      <div className="main-wrapper">
        <Header activeMenu={"course"} />

        <div className="breadcrumb-bar">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-12">
                <h2 className="breadcrumb-title">Thực học và Thực chiến</h2>
                <nav aria-label="breadcrumb" className="page-breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="/">Trang chủ</a>
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      Khóa học
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>

        <section className="course-content">
          <div className="container">
            <div className="row">
              <div className="col-lg-9">
                <GridInnerPage filter={filter} search={search} />
              </div>
              <div className="col-lg-3 theiaStickySidebar">
                <div className="stickysidebar">
                  <div className="filter-clear">
                    <div class="card search-widget blog-search blog-widget">
                      <div class="card-body">
                        <form class="search-form">
                          <div class="input-group">
                            <input
                              type="text"
                              placeholder="Tìm kiếm"
                              defaultValue={params.get("search")}
                              class="form-control"
                              onChange={(e) => {
                                setSearch(e.target.value);
                              }}
                            />
                            <button type="submit" class="btn btn-primary">
                              <i class="fa fa-search"></i>
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                    {/* Search Filter */}
                    <div className="card search-filter">
                      <div className="card-body">
                        <div className="filter-widget mb-0">
                          <div className="categories-head d-flex align-items-center">
                            <h4>Danh mục đào tạo</h4>
                          </div>
                          <ConfigProvider
                            theme={{
                              token: {
                                colorPrimary: "#FF5364",
                              },
                            }}
                          >
                            <Tree
                              checkable
                              treeData={danhMucDaoTao}
                              checkedKeys={filter}
                              onCheck={(checkedKeys) => {
                                setFilter(checkedKeys);
                              }}
                            />
                          </ConfigProvider>
                        </div>
                      </div>
                    </div>

                    <div style={{ textAlign: "center" }}>
                      {quangCao?.map(({ hinhAnh, link }) => (
                        <a href={`${link}`} target="_blank">
                          <img src={`${hinhAnh.path}`} width={"300"} style={{ marginBottom: 10 }} />
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </>
  );
};

export default CourseGrid;
