import { useQuery } from "@tanstack/react-query";
import { List } from "antd";
import { Link } from "react-router-dom";
import clientService from "../../services/http-client";
import Footer from "../footer";
// import Header from "../header";
import dayjs from "dayjs";
import Header from "../header";
import { Icon22 } from "../imagepath";

const BlogGrid = () => {
  const { data } = useQuery({
    queryKey: ["baiVietFind"],
    queryFn: () => clientService.api.baiVietFind(),
  });
  return (
    <>
      <div className="main-wrapper">
        <Header activeMenu={"news"} />
        <div className="breadcrumb-bar">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-12">
                <h2 className="breadcrumb-title">Thông tin về đào tạo</h2>
                <nav aria-label="breadcrumb" className="page-breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="/">Trang chủ</a>
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      Thông tin
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <section className="course-content">
          <div className="container">
            <div className="row">
              <div className="col-lg-9 col-md-12">
 
                <List
                  dataSource={data}
                  pagination={{
                    pageSize: 9,
                    align: "center",
                    total: data?.length,
                  }}
                  grid={{
                    gutter: 32,
                    xs: 1,
                    sm: 1,
                    md: 2,
                    lg: 2,
                    xl: 2,
                    xxl: 2,
                  }}
                  renderItem={(item, index) => {
                    return (
                      <div className="col-md-12 col-sm-12" key={index}>
                        {/* Blog Post */}
                        <div className="blog grid-blog">
                          <div className="blog-image">
                            <Link to={`/news-details/${item.id}`}>
                              <img className="img-fluid" src={item.hinhAnh.path} alt="Post Image" />
                            </Link>
                          </div>
                          <div className="blog-grid-box">
                            <div className="blog-info clearfix">
                              <div className="post-left">
                                <ul>
                                  <li>
                                    <img className="img-fluid" src={Icon22} alt="" />
                                    {dayjs(item.createdAt).format("DD/MM/YYYY")}
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <h3 className="blog-title">
                              <Link to={`/news-details/${item.id}`}>{item.tieuDe}</Link>
                            </h3>
                            <div className="blog-content blog-read">
                              <p>{item.moTa}</p>
                              <Link to={`/news-details/${item.id}`} className="read-more btn btn-primary">
                                Chi tiết
                              </Link>
                            </div>
                          </div>
                        </div>
                        {/* /Blog Post */}
                      </div>
                    );
                  }}
                />
         
              </div>
              {/* Blog Sidebar */}
              <div className="col-lg-3 col-md-12 sidebar-right theiaStickySidebar">
                <div className="stickysidebar">
                  {/* Latest Posts */}
                  <div className="card post-widget blog-widget">
                    <div className="card-header">
                      <h4 className="card-title">Các bài viết khác</h4>
                    </div>
                    <div className="card-body">
                      <ul className="latest-posts">
                        {data?.map((item, index) => (
                          <li>
                            <div className="post-thumb">
                              <Link to={`/news-details/${item.id}`}>
                                <img className="img-fluid" src={item.hinhAnh.path} alt="" />
                              </Link>
                            </div>
                            <div className="post-info">
                              <h4>
                                <Link to={`/news-details/${item.id}`}>{item.tieuDe}</Link>
                              </h4>
                              <p>
                                <img className="img-fluid" src={Icon22} alt="" />
                                {dayjs(item.createdAt).format("DD/MM/YYYY")}
                              </p>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  {/* /Latest Posts */}
                </div>
              </div>
              {/* /Blog Sidebar */}
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};

export default BlogGrid;
