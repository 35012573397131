import { BrowserRouter, Route, Routes } from "react-router-dom";
import BlogDetails from "./components/blog/blogDetails";
import BlogGrid from "./components/blog/blogGrid";
import ChungNhan from "./components/pages/chung-nhan";
import CourseDetails from "./components/pages/course/courseDetails";
import CourseGrid from "./components/pages/course/courseGrid";
import ComingSoon from "./components/pages/error/comingSoon";
import Error404 from "./components/pages/error/error404";
import Error500 from "./components/pages/error/error500";
import Faq from "./components/pages/faq";
import ForgotPassword from "./components/pages/forgotPassword";
import Login from "./components/pages/login";
import Logout from "./components/pages/logout";
import Register from "./components/pages/register";
import Support from "./components/pages/support";
//harish
import { Home } from "./components/home";

import About from "./components/pages/about";
import CourseEnroll from "./components/pages/course/courseEnroll";
import ThongTin from "./components/pages/thongTin";
const Approuter = () => {
  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route path="/" element={<Home />} />
        {/* Blog */}
        <Route path="/news" element={<BlogGrid />} />
        <Route path="/news-details/:id" element={<BlogDetails />} />
        <Route path="/chung-nhan/:id" element={<ChungNhan />} />
        {/* Pages */}
        <Route path="/course" element={<CourseGrid />} />
        <Route path="/course-details/:id" element={<CourseDetails />} />
        <Route path="/course-details/:id/doc/:docId" element={<CourseDetails />} />
        <Route path="/course-enroll/:id" element={<CourseEnroll />} />
        <Route path="/about" element={<About />} />
        <Route path="/chinh-sach" element={<ThongTin />} />
        <Route path="/come-soon" element={<ComingSoon />} />
        <Route path="/error-404" element={<Error404 />} />
        <Route path="/error-500" element={<Error500 />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/support" element={<Support />} />
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />;
        <Route path="/register" element={<Register />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Approuter;
