import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { Link, useParams } from "react-router-dom";
import clientService from "../../services/http-client";
import Footer from "../footer";
import Header from "../header";
import { Icon22 } from "../imagepath";
const BlogDetails = () => {
  const { id } = useParams();
  const { data, isLoading } = useQuery({
    queryKey: ["baiVietFindById", { id }],
    queryFn: () => clientService.api.baiVietFindById(id),
    enabled: !!id,
  });
  const { data: listBaiViet } = useQuery({
    queryKey: ["baiVietFind"],
    queryFn: () => clientService.api.baiVietFind(),
  });
  return (
    <>
      <div className="main-wrapper">
        <Header activeMenu={"news"} />
        <div className="breadcrumb-bar">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-12">
                <h2 className="breadcrumb-title">Thông tin về đào tạo</h2>
                <nav aria-label="breadcrumb" className="page-breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="/">Trang chủ</a>
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      Thông tin
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <section className="course-content">
          <div className="container">
            <div className="row">
              <div className="col-lg-9 col-md-12">
                {/* Blog Post */}
                <div className="blog">
                  <div className="blog-image">
                    <img className="img-fluid" src={data?.hinhAnh?.path} alt="Post Image" />
                  </div>
                  <div className="blog-info clearfix">
                    <div className="post-left">
                      <ul>
                        <li>
                          <img className="img-fluid" src={Icon22} alt="" />
                          {dayjs(data?.createdAt).format("DD/MM/YYYY")}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <h3 className="blog-title">{data?.tieuDe}</h3>
                  <div className="blog-content">
                    <div>
                      <div dangerouslySetInnerHTML={{ __html: data?.noiDung }} />
                    </div>
                  </div>
                </div>
                {/* /Blog Post */}
              </div>
              {/* Blog Sidebar */}
              <div className="col-lg-3 col-md-12 sidebar-right theiaStickySidebar">
                {/* Latest Posts */}
                <div className="card post-widget blog-widget">
                  <div className="card-header">
                    <h4 className="card-title">Các bài viết khác</h4>
                  </div>
                  <div className="card-body">
                    <ul className="latest-posts">
                      {listBaiViet?.map((item, index) => (
                        <li>
                          <div className="post-thumb">
                            <Link to={`/news-details/${item.id}`}>
                              <img className="img-fluid" src={item.hinhAnh.path} alt="" />
                            </Link>
                          </div>
                          <div className="post-info">
                            <h4>
                              <Link to={`/news-details/${item.id}`}>{item.tieuDe}</Link>
                            </h4>
                            <p>
                              <img className="img-fluid" src={Icon22} alt="" />
                              {dayjs(item.createdAt).format("DD/MM/YYYY")}
                            </p>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                {/* /Latest Posts */}
              </div>
              {/* /Blog Sidebar */}
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </>
  );
};

export default BlogDetails;
